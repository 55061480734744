/*
*  Mixins
*/
@import "components/_grid-system.scss";

/*
* Buttons
*/

@mixin button-block() {
    background-color: $third-color;
    color: $black;
    font-weight: 700;
    padding: 10px 15px;
    display: inline-block;
    backface-visibility: hidden;
    transform: translateZ(0);
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-size: 14px;

    @media (min-width: $screen-lg-min) {
        padding: 12px 20px 11px;
    }
}

@mixin button-block__primary() {
    background-color: $secondary-color;
    color: $white;
}

@mixin button-block__accent() {
    background-color: $secondary-color;
    color: $white;
}

@mixin button-block__line() {
    height: 50px; 
    line-height: 50px; 
    text-align: center; 
    background-color: #00c300;
    color: $white;
}

@mixin button-block__white() {
    background-color: $white;
    color: $black;
    border: thin solid $black;

    @media (min-width: $screen-lg-min) {
        padding: 11px 20px 9px;
    }
}

@mixin button-like-link() {
    @include button-block;
    padding: 11px 15px;

    span {
        display: inline-block;
        position: relative;
        @media (min-width: $screen-lg-min) {
            line-height: 18px;
        }
    }
}

@mixin button-block__back() {
    @include button-block;
    padding: 9px 15px 8px;

    span {
        display: inline-block;
        position: relative;
        @media (min-width: $screen-lg-min) {
            line-height: 18px;
        }
    }
}

@mixin no-button {
    padding: 0;
    background: transparent;
    color: $primary;
    font-weight: 400;
    font-size: 14px;
    &:hover {
        background: transparent;
        color: $text-dark;
    }
}

/*
* Price
*/

@mixin price-box() {
    font-size: 18px;
    line-height: 1;
    font-weight: 700;

    @media (max-width: $screen-xs-min - 1) {
        font-size: 16px;
    }

    .price-label {
        display: none;
    }

    span.price {
        display: flex;
        align-items: flex-end;
    }

    span.currency-symbol {
        color: $primary;
        font-size: 13px;
        line-height: 13px;
        font-weight: 500;
        margin-left: 5px;
        margin-bottom: 2px;

        @media (max-width: $screen-xs-min - 1) {
            font-size: 11px;
            line-height: 11px;
        }
    }

    .special-price {
        color: $secondary-color;
    }

    .old-price {
        text-decoration: line-through;
        text-decoration-color: $secondary-color;
        color: $default;
        margin-bottom: 3px;

        span {
            vertical-align: baseline;

            &.price {
                font-size: 14px;
                font-weight: 400;
                margin-right: 5px;
            }

            &.currency-symbol {
                display: none;
            }
        ;
        }
    }
}

/*
* Transform Origin
*/
@mixin transform-origin($trfm-o) {
    transform-origin: $trfm-o;
}

/*
* Transform
*/
@mixin transform($trfm) {
    transform: $trfm;
}

/*
* Triangle
*/
@mixin triangle($dir, $w, $h, $clr) {
    width: 0;
    height: 0;
    border-style: solid;

    @if $dir == top {
        border-width: 0 ceil($w/2) $h ceil($w/2);
        border-color: transparent transparent $clr transparent;
    } @else if $dir == bottom {
        border-width: $h ceil($w/2) 0 ceil($w/2);
        border-color: $clr transparent transparent transparent;
    } @else if $dir == left {
        border-width: ceil($h/2) $w ceil($h/2) 0;
        border-color: transparent $clr transparent transparent;
    } @else if $dir == right {
        border-width: ceil($h/2) 0 ceil($h/2) $w;
        border-color: transparent transparent transparent $clr;
    } @else if $dir == top_left {
        border-width: $w $w 0 0;
        border-color: $clr transparent transparent transparent;
    } @else if $dir == top_right {
        border-width: 0 $w $w 0;
        border-color: transparent $clr transparent transparent;
    } @else if $dir == bottom_right {
        border-width: 0 0 $w $w;
        border-color: transparent transparent $clr transparent;
    } @else if $dir == bottom_left {
        border-width: $w 0 0 $w;
        border-color: transparent transparent transparent $clr;
    }
}

/*
* Font Icon
*/

@mixin font-icon($icon:'\e5cd', $font-family:$default-icon-family) {
    line-height: inherit;
    &:before {
        content: $icon;
        display: inline-block;
        font-family: $font-family;
        font-weight: 400;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0);
    }
}

@mixin font-icon__pseudo($icon:'\e5c7', $font-family:$default-icon-family) {
    content: $icon;
    display: inline-block;
    font-family: $font-family;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    transform: translate(0, 0);
}

/*
* Clearfix
*/
@mixin clearfix {
    &:before,
    &:after {
        display: table;
        content: "";
        line-height: 0;
    }

    &:after {
        clear: both;
    }
}

/*
*  Spinner
*/
@mixin custom-spinner($radius: 90px, $color: $primary) {
    box-sizing: border-box;
    text-align: center;
    width: $radius;
    height: $radius;
    line-height: $radius;
    text-transform: uppercase;

    &:before,
    &:after {
        opacity: 0;
        box-sizing: border-box;
        content: "\0020";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 60px;
        border: 3px solid transparentize($color, 0);
        box-shadow: 0 0 30px transparentize($color, 0), transparentize($color, 0);
    }
    &:before {
        z-index: 2;
        animation: cssload-gogoloader 2.3s infinite;
    }
    &:after {
        z-index: 1;
        animation: cssload-gogoloader 2.3s infinite 1.15s;
    }

    @keyframes cssload-gogoloader {
        0% {
            transform: scale(0);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
    @-o-keyframes cssload-gogoloader {
        0% {
            -o-transform: scale(0);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            -o-transform: scale(1);
            opacity: 0;
        }
    }
    @-ms-keyframes cssload-gogoloader {
        0% {
            -ms-transform: scale(0);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            -ms-transform: scale(1);
            opacity: 0;
        }
    }
    @-webkit-keyframes cssload-gogoloader {
        0% {
            -webkit-transform: scale(0);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            opacity: 0;
        }
    }
    @-moz-keyframes cssload-gogoloader {
        0% {
            -moz-transform: scale(0);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            -moz-transform: scale(1);
            opacity: 0;
        }
    }
}

/*
* Usefull Functions
*/

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function rem($px-size) {
    @if ($debug) {
        @return $px-size;
    } @else {
        @return str-replace(#{($px-size/100)}, px, rem);
    }
}

@function remove($list, $value, $recursive: false) {
    $result: ();

    @for $i from 1 through length($list) {
        @if type-of(nth($list, $i)) == list and $recursive {
            $result: append($result, remove(nth($list, $i), $value, $recursive));
        } @else if nth($list, $i) != $value {
            $result: append($result, nth($list, $i));
        }
    }

    @return $result;
}

@function sort($list) {
    $sortedlist: ();
    @while length($list) > 0 {
        $value: nth($list, 1);
        @each $item in $list {
            @if $item < $value {
                $value: $item;
            }
        }
        $sortedlist: append($sortedlist, $value, 'space');
        $list: remove($list, $value);
    }
    @return $sortedlist;
}

@function average($list) {
    $sum: 0;

    @each $i in $list {
        $sum: $sum + $i;
    }

    @return $sum / length($list);
}

@function headings() {
    @return "h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4,.heading-5, .heading-6";
}

@function cols($resolution) {
    $selector: ();
    @each $j in 1, 2, 3, 4, 6, 12 {
        $selector: append($selector, "> .col-#{$resolution}-#{$j}:nth-child(n + #{(12/$j)+1})", comma);
    }

    $selector: append($selector, "> .col-#{$resolution}-8.col-#{$resolution}-preffix-2");

    @return $selector;
}

@function cols-full($resolution) {
    $selector: ();
    @for $j from 1 through 12 {
        $selector: append($selector, "> .col-#{$resolution}-#{$j}:nth-child(n)", comma);
    }

    @return $selector;
}

@mixin make-flow-offset($lg-offset, $md-offset: $lg-offset, $sm-offset: $md-offset, $xs-offset: $sm-offset) {
    & > * + * {
        margin-top: $xs-offset;
    }

    html:not(.lt-ie10) & {
        @media (min-width: $screen-xs-min) {
            #{cols-full("xs")} {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-xs-min) {
            #{cols("xs")} {
                margin-top: $xs-offset;
            }
        }

        @media (min-width: $screen-sm-min) {
            #{cols-full("sm")} {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-sm-min) {
            #{cols("sm")} {
                margin-top: $sm-offset;
            }
        }

        @media (min-width: $screen-md-min) {
            #{cols-full("md")} {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-md-min) {
            #{cols("md")} {
                margin-top: $md-offset;
            }
        }

        @media (min-width: $screen-lg-min) {
            #{cols-full("lg")} {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-lg-min) {
            #{cols("lg")} {
                margin-top: $lg-offset;
            }
        }
    }
}

// Usefull Functions

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function rem($px-size) {
    @if ($debug == 1) {
        @return $px-size;
    } @else {
        @return str-replace(#{($px-size/100)}, px, rem);
    }
}

@function remove($list, $value, $recursive: false) {
    $result: ();

    @for $i from 1 through length($list) {
        @if type-of(nth($list, $i)) == list and $recursive {
            $result: append($result, remove(nth($list, $i), $value, $recursive));
        } @else if nth($list, $i) != $value {
            $result: append($result, nth($list, $i));
        }
    }

    @return $result;
}

@function sort($list) {
    $sortedlist: ();
    @while length($list) > 0 {
        $value: nth($list, 1);
        @each $item in $list {
            @if $item < $value {
                $value: $item;
            }
        }
        $sortedlist: append($sortedlist, $value, 'space');
        $list: remove($list, $value);
    }
    @return $sortedlist;
}

@function average($list) {
    $sum: 0;

    @each $i in $list {
        $sum: $sum + $i;
    }

    @return $sum / length($list);
}

@function headings() {
    @return "h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4,.heading-5, .heading-6";
}

@function cols($resolution) {
    $selector: ();
    @each $j in 1, 2, 3, 4, 6, 12 {
        $selector: append($selector, "> .col-#{$resolution}-#{$j}:nth-child(n + #{(12/$j)+1})", comma);
    }

    $selector: append($selector, "> .col-#{$resolution}-8.col-#{$resolution}-preffix-2");

    @return $selector;
}

@function cols-full($resolution) {
    $selector: ();
    @for $j from 1 through 12 {
        $selector: append($selector, "> .col-#{$resolution}-#{$j}:nth-child(n)", comma);
    }

    @return $selector;
}

@mixin make-flow-offset($lg-offset, $md-offset: $lg-offset, $sm-offset: $md-offset, $xs-offset: $sm-offset) {
    & > * + * {
        margin-top: $xs-offset;
    }

    html:not(.lt-ie10) & {
        @media (min-width: $screen-xs-min) {
            #{cols-full("xs")} {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-xs-min) {
            #{cols("xs")} {
                margin-top: $xs-offset;
            }
        }

        @media (min-width: $screen-sm-min) {
            #{cols-full("sm")} {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-sm-min) {
            #{cols("sm")} {
                margin-top: $sm-offset;
            }
        }

        @media (min-width: $screen-md-min) {
            #{cols-full("md")} {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-md-min) {
            #{cols("md")} {
                margin-top: $md-offset;
            }
        }

        @media (min-width: $screen-lg-min) {
            #{cols-full("lg")} {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-lg-min) {
            #{cols("lg")} {
                margin-top: $lg-offset;
            }
        }
    }
}

/*
* @subsection   RD Navbar Toggles
*/

@mixin toggle($size: 48px, $font-size: 24px, $color: #111) {
    display: inline-block;
    position: relative;
    width: $size;
    height: $size;
    font-size: $font-size;
    line-height: $size;
    text-align: center;
    cursor: pointer;
    color: $color;
}

@mixin toggle-icons-via-rotation($size: 48px, $font-size: 24px, $color: #111, $icon-before: '\f002', $icon-after: '\f00d', $trigger-class: '') {
    > span {
        @include toggle($size, $font-size, $color);
        &:before,
        &:after {
            transition: .3s ease;
        }

        &:before, &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:before {
            content: $icon-before;
            @include transform(rotate(0deg) scale(1));
            opacity: 1;
            visibility: visible;
        }

        &:after {
            content: $icon-after;
            @include transform(rotate(-90deg) scale(0));
            opacity: 0;
            visibility: hidden;
        }
    }

    &.active {
        > span {
            &:before {
                opacity: 0;
                visibility: hidden;
                @include transform(rotate(90deg) scale(0));
            }

            &:after {
                @include transform(rotate(0deg) scale(1));
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*
* @subsection   Desktop Top Link Icons
*/

@mixin desktop-top-link-icon ($size: 50px, $iconUrl: '') {
    font-size: 14px;
    font-weight: 700;
    .icon {
        &:before {
            display: block;
            width: 50px;
            height: 50px;
            content: '';
            background: $iconUrl center center no-repeat;
            background-size: 50px 50px;
            margin: auto;
        }
    }
}

/*
* @subsection   Transition
*/
// Transition
@mixin transition($trsn) {
    -moz-transition: $trsn;
    -o-transition: $trsn;
    -webkit-transition: $trsn;
    transition: $trsn;
}