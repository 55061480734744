//
// Grid system
// --------------------------------------------------

$column-count: 12;
$column-width: 80px;
$column-xwidth: 120px;
$gutter-width: 30px;

$row-ws: 0;

$container-xs: 300px;
$container-sm: 720px + $gutter-width;
$container-md: 940px + $gutter-width;
$container-lg: 1150px + $gutter-width;
$container-xl: $column-count*$column-width + $gutter-width*($column-count - 1);
$container-xxl: $column-count*$column-xwidth + $gutter-width*$column-count;

// Layouts
$layout-boxed-width: 1920px;

// Resolutions
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xl-min: 1400px;
$screen-xxl-min: 1800px;

$screen-mob-min: $screen-md-min; // break-point for mobile styles

// Fonts
$default-font-family: 'Sukhumvit Set', sans-serif;
$secondary-font-family: $default-font-family;
$special-font-family: 'Sarina', cursive;
$default-font-weight: 400;
$font-weight__semibold: 600;
$font-weight__bold: 700;
$default-font-size: 15px;
$default-line-height: 1.5em;
$ctis-font-family: 'DB Heavent', sans-serif;

// Icons
$material-icons: 'Material Icons';
$icon-mini: 'Nucleo Mini';
$icon-outline: 'Nucleo Outline';
$default-icon-family: 'Nucleo Mini';
$secondary-icon-family: 'Linearicons';
$out-icon-family: 'fl-outicons';
$fa: 'FontAwesome';

// Colors
$white: #FFF;
$gray: #F4F4F4;
$gray_9f: #9F9F9F;
$gray_7f: #7F7F7F;
$black: #000;
$default: $black;

$allow: #3bb141;
$denied: #ff5555;
$attention: #ff5555;

$bg-light: #f5f5f5;
$bg-dark: #272727;

$primary: #000;
$accent: #F04544;
$secondary-color: #F04544;
$third-color: #c2c2c2;
$text-dark: #000000;
$text-light: #838383;
$text-lightest: #b4b4b4;
$unactive: #999999;
$red-color: #FF0202;

$review-star: #EE2524;

$border-color: #f5f5f5;
$dark-border-color: #b4b4b4;

// Smart header vars

$sm-header-main-color: $white;
$sm-header-contrast-color: $secondary-color;
$sm-header-text-color: $text-light;
$sm-header-hover-color: $primary;
$sm-header-panel-height: 56px;

$sm-header-item-fontsize: 14px;
$sm-header-item-lineheight: 16px;
$sm-header-item-padding: 17px;
$sm-header-item-border: 1px solid $border-color;
$sm-header-item-bg-1: $third-color;
$sm-header-item-bg-2: $white;

$rd-navbar-panel-height: 56px;

$default-header-shadow: 0 3px 5px 0 rgba(0, 0, 0, .25);

//Indents
$indent-base: 2rem;
$indent-large:  $indent-base * 2;
$indent-small:  $indent-base / 2;

//Links
$link-regular:  $primary;
$link-hover:    $accent;
$link-active:   $text-dark;
$link-alt:      $default;

//Transition
$default-transition: .3s ease;

//Inputs
$input-bg-color:        #f5f5f5;